export const SESSION_STORAGE_KEYS = {
  scaUrl: 'scaUrl',
  returnUrl: 'returnUrl',
  scheme: 'scheme',
  configuration: 'configuration',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  currentUser: 'currentUser',
  navigate: 'navigate',
  userId: 'userId',
  TransactionDetailToDashboard: 'TransactionDetailToDashboard',
  sendCashCode: 'sendCashCode',
  contactUsPartnerEnabled: 'contactUsPartnerEnabled',
  partnerUrl: 'partnerUrl',
  exists: 'exists',
  email: 'email',
  layoutTestsType: 'layoutTestsType',
  adyenStoredValue: 'adyenStoredValue',
  brand: 'brand'
};
