import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { UtilsService } from '../services';
import { OpeningHours, OpeningHoursWeekday } from '../interfaces';

@Pipe({
  name: 'sortByDay'
})
export class SortByDayPipe implements PipeTransform {

  constructor(
    private translocoService: TranslocoService,
    private utilsService: UtilsService
  ) {}

  transform(openingHours: OpeningHours[]): OpeningHoursWeekday[] {
    return openingHours
      .sort((a, b) => this.utilsService.sorter[a.key.toLowerCase()] - this.utilsService.sorter[b.key.toLowerCase()])
      .map(item => {
        const day = 'authorized.day_' + item.key.toLowerCase();
        const key = this.translocoService.translate(day).slice(0, 3);

        return {...item, key, weekday: item.key.toLowerCase()};
      });
  }

}
