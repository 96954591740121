import { PolicyUrls } from '../interfaces';

export const policyUrls: PolicyUrls = {
  en: {
    appPrivacyPolicy: 'app-privacy-policy-twint-2/',
    termsConditions: 'terms-of-use-twint-3/'
  },
  de: {
    appPrivacyPolicy: 'ch-de/app-privacy-policy-twint/',
    termsConditions: 'ch-de/terms-of-use-twint-4/'
  },
  fr: {
    appPrivacyPolicy: 'ch-fr/app-privacy-policy-twint-4/',
    termsConditions: 'ch-fr/terms-of-use-twint/'
  },
  it: {
    appPrivacyPolicy: 'ch-it/app-privacy-policy-twint-3/',
    termsConditions: 'ch-it/terms-of-use-twint-2/'
  },
  es: {
    appPrivacyPolicy: 'app-privacy-policy-twint-2/',
    termsConditions: 'terms-of-use-twint-3/'
  }
};
