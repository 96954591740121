import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { NgProgress } from '@ngx-progressbar/core';
import { freshDeskLoad } from '../assets/libs/freshdesk.js';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LocationService, ScreenRotateService, UserLanguagesService, UtilsService } from './services';
import { ABCLayoutTests, LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from './constants';
import { slideInAnimations } from './animations/routeTransitions';

@Component({
  selector: 'app-root',
  template: `
    <div class="app-wrapper"
         [@routeAnimations]="prepareRoute(outlet)"
         [class.light-theme]="currentTheme === 'white'"
         appWrapperColor>
      <router-outlet #outlet="outlet"></router-outlet>
      <ng-container *ngIf="orientationScreen === 90 || orientationScreen === -90">
        <app-landscape-mode></app-landscape-mode>
      </ng-container>
    </div>
    <ng-progress [color]="'#ff0069'" [thick]="true"></ng-progress>
  `,
  styles: [`
    .app-wrapper {
      height: 100%;
      overflow-y: scroll;
    }
  `],
  animations: [slideInAnimations]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  orientationScreen: number;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private progressBar: NgProgress,
    private userLanguagesService: UserLanguagesService,
    private locationService: LocationService,
    private screenRotateService: ScreenRotateService,
    private languagesService: UserLanguagesService,
    private utilsService: UtilsService
  ) {
    this.detectOrientation(window.orientation);

    screenRotateService.resize$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((target: any) => {
        this.detectOrientation(target.orientation);
      });
  }

  get currentTheme(): string {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.theme);
  }

  ngOnInit(): void {
    const locale = this.languagesService.getUserLanguageWithLocal();
    freshDeskLoad(locale);
  }

  ngAfterViewInit(): void {
    // https://sonectapp.atlassian.net/browse/SO-12320
    // A-B Layout Testing
    this.utilsService.layoutValue$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (type: string) => {
          const currentType = sessionStorage.getItem(SESSION_STORAGE_KEYS.layoutTestsType);
          if (type === ABCLayoutTests.A) {
            this.locationService.requestUserLocation();
            return;
          }

          if (type === null && currentType === ABCLayoutTests.A) {
            this.locationService.requestUserLocation();
          }
        }
      })
  }

  // detect view changes
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData?.['animation']
    );
  }

  private detectOrientation(orientation): void {
    this.orientationScreen = orientation;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
