import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { Translations } from '@lokalise/node-api/dist/collections';

import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { TransactionService } from './transaction.service';
import { AuthenticationService } from './authentication.service';
import { Response, TransactionDetails, TransactionPaymentStatus, Spotlight } from '../interfaces';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpotlightService {
  private http = inject(HttpClient);
  private config = inject(AuthenticationService).getAppConfiguration();
  private authenticationService = inject(AuthenticationService);
  private transactionService = inject(TransactionService);
  private translocoService = inject(TranslocoService);

  get spotlightOrder(): string[] {
    return this.config?.spotlight?.['condition_1'];
  }

  get defaultUserOrder(): string[] {
    return this.config?.spotlight?.['condition_2'];
  }

  get heavyUserOrder(): string[] {
    return this.config?.spotlight?.['condition_3'];
  }

  getSpotlightAll(): Observable<Spotlight[]> {
    const spotlight$: Observable<Spotlight[]> = this.http.get(`${ environment.baseurl.api }/spotlight/all`)
      .pipe(map((response: Response) => response.payload));
    const confirmedTransactionsLength$: Observable<number> = this.transactionService.getTransactions()
      .pipe(
        map((response: Response) => response.payload?.filter((item) => item.statusDesc === TransactionPaymentStatus.Confirmed)),
        map((confirmedList: TransactionDetails[]) => confirmedList.length)
      );

    const source$: Observable<[Spotlight[], number]> = forkJoin([spotlight$, confirmedTransactionsLength$]);

    return source$
      .pipe(
        switchMap((source: [Spotlight[], number]) => {
          // first should load translations
          return this.translocoService.selectTranslation('authorized')
            .pipe(
              filter((translation: Translations) => Boolean(translation)),
              map(() => source)
            )
        }),
        map(([spotlight, confirmedTransactionsLength]: [Spotlight[], number]) => {
          const userPartner = this.authenticationService.getUser().onboardedBy;
          if (userPartner === 'twint') {
            if (confirmedTransactionsLength < 5) {
              return this.defaultUserOrder.map((order: string) => spotlight.find((slide: Spotlight) => slide.id === +order));
            }

            return this.heavyUserOrder.map((order: string) => spotlight.find((slide: Spotlight) => slide.id === +order));
          }

          if (this.spotlightOrder?.length) {
            return this.spotlightOrder.map((order: string) => spotlight.find((slide: Spotlight) => slide.id === +order));
          }

          return spotlight;
        }),
        catchError((err: HttpErrorResponse) => {
          console.warn(err);
          return of(this.getErrorSlide());
        })
      );
  }

  getErrorSlide(): Spotlight[] {
    return [{
      id: 0,
      imagePath: 'assets/img/illustrations/spotlight/spotlight_tutorial.png',
      title: this.translocoService.translate('authorized.dashboard_spotlight_id1_title'),
      description: this.translocoService.translate('authorized.dashboard_spotlight_id1_desc'),
      link: this.translocoService.translate('authorized.dashboard_spotlight_id1_link')
    }]
  }
}
