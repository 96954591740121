import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { PromocodeUI, Response } from '../interfaces';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromocodeService {

  promotion$ = new BehaviorSubject<PromocodeUI>({ endDate: null, name: null, startDate: null, status: null });

  constructor(private http: HttpClient) {
  }

  activatePromocode(promocode: string): Observable<Response> {
    return this.http.post<Response>(`${ environment.baseurl.api }/user/promocode/activate`, { promocode });
  }
}
