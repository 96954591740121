import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { THEME_PALETTE, LOCAL_STORAGE_KEYS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  setTheme(name: string): void {
    const options = THEME_PALETTE[name];
    localStorage.setItem(LOCAL_STORAGE_KEYS.theme, name);
    Object.keys(options).forEach((key: string) => {
      this.document.documentElement.style.setProperty(`--${ key }`, options[key]);
    });
  }
}
