import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { OnboardingService } from '../services';
import { OnboardingSteps, Response } from '../interfaces';

export const onBoardingResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  onBoardingService: OnboardingService = inject(OnboardingService)
): Observable<OnboardingSteps[] | null> => {
  const onboardingData: OnboardingSteps[] = onBoardingService.getOnboardingSteps();
  return onboardingData ? of(onboardingData) : onBoardingService.getSteps()
    .pipe(
      map((response: Response) => {
        onBoardingService.setOnboardingSteps(response.payload);
        return response.payload;
      }),
      catchError((err) => {
        console.warn(err);
        return of(null);
      })
    )
}
