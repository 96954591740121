export enum AtmCategory {
  generic = 'GENERAL',
  kiosk = 'KIOSK',
  restaurant = 'RESTAURANT',
  bakery = 'BAKERY',
  shop = 'SHOP',
  carWash = 'CAR-WASH',
  gasStation = 'GAS-STATION',
  taxi = 'TAXI',
  coffeeShop = 'COFFEE-SHOP',
  foodTruck = 'FOOD-TRUCK',
  onlineDelivery = 'ONLINE-DELIVERY',
  pizzeria = 'PIZZERIA',
  pharmacy = 'PHARMACY',
  bar = 'BAR',
  events = 'CLUB',
  hotel = 'HOTEL',
  supermarket = 'SUPERMARKET',
}

export enum AtmParent {
  valora = 'valora',
  volg = 'volg',
}
