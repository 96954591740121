import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { BehaviorSubject } from 'rxjs';

import { DE, EN, ES, ES_MX, FR, IT } from '../constants';

const DEFAULT_LANGUAGE = EN;

@Injectable({
  providedIn: 'root'
})

export class UserLanguagesService {
  userLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(DEFAULT_LANGUAGE);
  userLanguageWithLocal: BehaviorSubject<string> = new BehaviorSubject<string>(DEFAULT_LANGUAGE);

  readonly availableLangs = [EN, IT, DE, FR, ES, ES_MX.toLowerCase];

  constructor(
    private translocoService: TranslocoService
  ) {
    this.getBrowserLocales();
  }

  getBrowserLocales(): void {
    const browserLocales = navigator.languages ?? [navigator.language];

    if (!browserLocales || !browserLocales.length) {
      this.userLanguage.next(DEFAULT_LANGUAGE);
      return;
    }

    const alluserLangs = browserLocales.map(locale => {
      const trimmedLocale = locale.trim();

      // if the locale contains 'gsw' (Swiss German), replace it by 'de' (German)
      return trimmedLocale.split(/-|_/)[0].replace('gsw', 'de');
    });

    const userLang = alluserLangs.find(lang => this.availableLangs.includes(lang)) ?? DEFAULT_LANGUAGE;

    this.userLanguage.next(userLang);
    this.userLanguageWithLocal.next(browserLocales?.[0] || DEFAULT_LANGUAGE);
    this.translocoService.setActiveLang(userLang);
  }

  getUserLanguageWithLocal(): string {
    return this.userLanguageWithLocal.value;
  }

}
