import { PartnersLogin } from '../pages/protected/login/interfaces/partners-credentials.interface';
import { environment } from '../../environments/environment';

export const SONECT_LOGIN_CREDENTIALS: Partial<PartnersLogin> = {
  // Lottomatica
  DEV: {
    clientId: environment.lottomatica.clientId || '',
    clientSecret: environment.lottomatica.clientSecret || '',
    referenceId: 'dev300501'
  },
  TEST: {
    clientId: environment.lottomatica.clientId || '',
    clientSecret: environment.lottomatica.clientSecret || '',
    referenceId: 'web300503'
  }
};
