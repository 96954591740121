export enum TransactionStatus {
  Failed = -999,
  Expired = -9,
  Cancelled = -2,
  Rejected = -1,
  Pending = 1,
  Confirmed = 2,
  Pending_SCA = 3,
}

export enum TransactionPaymentStatus {
  Expired = 'EXPIRED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Authorized = 'AUTHORIZED',
  Failed = 'FAILED',
  Pending_SCA = 'PENDING_SCA',
}

export const transactionStatusToClassConfig: Map<TransactionStatus, string> = new Map([
  [TransactionStatus.Cancelled, 'sonect-orange-color'],
  [TransactionStatus.Confirmed, 'sonect-lime-color'],
  [TransactionStatus.Expired, 'sonect-grey-color'],
  [TransactionStatus.Failed, 'sonect-flamingo-color'],
  [TransactionStatus.Rejected, 'sonect-flamingo-color'],
  [TransactionStatus.Pending, '']
]);

export interface Transaction {
  id: string;
  from: {
    id: string,
    name: string,
    phone: string,
    rating: number
  };
  to: {
    id: string,
    address: {
      line1: string,
      line2: string,
      city: string,
      state: string,
      zip: string,
      name: string,
      formatted: string
    },
    name: string,
    onboardedBy: string,
    parent: string,
    phone: string,
    rating: number,
    referenceId: string,
    master: {
      address: {
        line1: string,
        line2: string,
        city: string,
        state: string,
        zip: string,
        name: string,
        formatted: string,
        onboardedBy: string,
        parent: string
      },
      name: string,
      onboardedBy: string,
      parent: string,
      phone: string,
      rating: number,
      referenceId: string
    }
  };
  amount: number;
  currency: string;
  fees: {
    amount: number,
    payer: string,
    status: string
  };
  commission: {
    sonect: number,
    partner: number,
    merchant: number
  };
  payment: {
    id: string,
    method: string,
    bankId: string,
    cardId: string,
    status: string,
    creationDate: Date,
    modificationDate: Date
  };
  type: number;
  sendTime: Date;
  expiryTime: Date;
  status: TransactionStatus;
  statusTime: Date;
  history: TransactionHistory[];
}

export interface TransactionHistory {
  what: {
    status: number;
    statusTime: string;
    to: {
      domain: string;
      phone: string;
      name: string;
    };
    type: 2;
    from: {
      name: string;
      phone: string;
    };
  };
  when: string;
  who: string;
}

export interface TransactionDetails {
  amount: number;
  code: string;
  commission: {
    merchant: number,
    partner: number,
    sonect: number
  };
  currency: string;
  expiryTime: number;
  error: TransactionDetailsError;
  fees: {
    $init: boolean,
    amount: number,
    payer: string,
    status: string
  };
  from: {
    additionalData: {
      partnerBrand: string;
      partnerUserId: string;
    };
    location: {
      city: '',
      zip: '',
      country: 'Switzerland',
      countryCode: 'CH'
    },
    domain: 'CH',
    id: number,
    name: string,
    onboardedBy: string,
    phone: string,
    rating: number
  };
  id: string;
  payment: {
    bankId: string,
    bankName: string,
    cardId: string,
    creationDate: string,
    method: string,
    modificationDate: string,
    platformId: string,
    status: string
  };
  referenceId: string;
  referenceId2: string;
  sca: {
    error: any,
    url: string;
  };
  sendTime: number;
  status: number;
  statusDesc: string;
  statusTime: number;
  title: string;
  to: {
    address: TransactionDetailsAddress,
    category: string;
    categoryText: string;
    domain: string;
    id: string;
    imagePath: string;
    master: {
      address: {
        geo: {
          coordinates: number[]
        }
      }
    };
    name: string;
    onboardedBy: string;
    owner: {
      email: string
    },
    parent: string,
    phone: string;
    rating: number;
    referenceId: string;
    sonectId: string;
  };
  type: number;
}

export interface TransactionList {
  byMonthAndYear: string;
  list: TransactionDetails[];
}

export interface TransactionDetailsAddress {
  city: string;
  country: string;
  countryCode: string;
  formatted: string;
  geo: TransactionDetailsAddressGeo;
  line1: string;
  line2: string;
  state: string;
  zip: string;
}

export interface TransactionDetailsAddressGeo {
  coordinates: number[];
  latitude: string;
  longitude: string;
  type: string;
}

export interface TransactionDetailsError {
  code: string;
  message: string;
  _id: string;
}

export interface TransactionPayload {
  amount: string;
  currency: string;
  paymentMethod: string;
  type: string
}



