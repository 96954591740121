import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

import { Subject } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appWrapperColor]'
})
export class WrapperColorDirective implements OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(
    private el: ElementRef,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        delay(100),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event) => {
        if ((event as RouterEvent).url === '/tutorial') {
          this.el.nativeElement.offsetParent.style.setProperty('background', '#000', 'important');
        } else {
          this.el.nativeElement.offsetParent.style.background = '';
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
