import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthenticationService } from '../../../services';
import { Response, User } from '../../../interfaces';

export const userResolver = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  authenticationService: AuthenticationService = inject(AuthenticationService)
): Observable<User | null> => {
  const user: User = authenticationService.getUser();
  return user ? of(user) : authenticationService.getUserInfoPayment()
    .pipe(
      map((response: Response) => {
        authenticationService.setUser(response.payload);
        return response.payload;
      }),
      catchError((err) => {
        console.warn(err);
        return of(null);
      })
    )
}
