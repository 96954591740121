import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  /*
  * SO-11720 - Cancellation flow shop picker
  * navFromCancellationScreenEvent$ - will check if /map opened from cancelled feedback -> shopList (UI - select on the map button)
  * navFromDashboardNPSScreenEvent$ - will check if /map opened from dashboard NPS -> shopList (UI - select on the map button)
  * */
  navFromCancellationScreenEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  navFromDashboardNPSScreenEvent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  randomizeEvent$ = new BehaviorSubject(NaN);

  // https://sonectapp.atlassian.net/browse/SO-12320
  layoutEvent$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  layoutValue$: Observable<string | null> = this.layoutEvent$.asObservable();

  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  sorter: { [key: string]: number } = {
    // "sunday": 0, // << if sunday is first day of week
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7
  };
  weekdays: string[] = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  countries = [
    {
      'callingCode': '355',
      'country': 'Albania',
      'cca2': 'AL',
      'cca3': 'ALB',
      'currency': 'ALL',
      'name': 'Albania',
      'region': 'Europe',
      'subregion': 'Southern Europe',
      'tld': [
        '.al'
      ]
    },
    {
      'callingCode': '43',
      'country': 'Austria',
      'cca2': 'AT',
      'cca3': 'AUT',
      'currency': 'EUR',
      'name': 'Austria',
      'region': 'Europe',
      'subregion': 'Western Europe',
      'tld': [
        '.at'
      ]
    },
    {
      'callingCode': '32',
      'country': 'Belgium',
      'cca2': 'BE',
      'cca3': 'BEL',
      'currency': 'EUR',
      'name': 'Belgium',
      'region': 'Europe',
      'subregion': 'Western Europe',
      'tld': [
        '.be'
      ]
    },
    {
      'callingCode': '359',
      'country': 'Bulgaria',
      'cca2': 'BG',
      'cca3': 'BGR',
      'currency': 'BGN',
      'name': 'Bulgaria',
      'region': 'Europe',
      'subregion': 'Eastern Europe',
      'tld': [
        '.bg'
      ]
    },
    {
      'callingCode': '385',
      'country': 'Croatia',
      'cca2': 'HR',
      'cca3': 'HRV',
      'currency': 'HRK',
      'name': 'Croatia',
      'region': 'Europe',
      'subregion': 'Southern Europe',
      'tld': [
        '.hr'
      ]
    },
    {
      'callingCode': '33',
      'country': 'France',
      'cca2': 'FR',
      'cca3': 'FRA',
      'currency': 'EUR',
      'name': 'France',
      'region': 'Europe',
      'subregion': 'Western Europe',
      'tld': [
        '.fr'
      ]
    },
    {
      'callingCode': '49',
      'country': 'Germany',
      'cca2': 'DE',
      'cca3': 'DEU',
      'currency': 'EUR',
      'name': 'Germany',
      'region': 'Europe',
      'subregion': 'Western Europe',
      'tld': [
        '.de'
      ]
    },
    {
      'callingCode': '30',
      'country': 'Greece',
      'cca2': 'GR',
      'cca3': 'GRC',
      'currency': 'EUR',
      'name': 'Greece',
      'region': 'Europe',
      'subregion': 'Southern Europe',
      'tld': [
        '.gr'
      ]
    },
    {
      'callingCode': '36',
      'country': 'Hungary',
      'cca2': 'HU',
      'cca3': 'HUN',
      'currency': 'HUF',
      'name': 'Hungary',
      'region': 'Europe',
      'subregion': 'Eastern Europe',
      'tld': [
        '.hu'
      ]
    },
    {
      'callingCode': '39',
      'country': 'Italy',
      'cca2': 'IT',
      'cca3': 'ITA',
      'currency': 'EUR',
      'name': 'Italy',
      'region': 'Europe',
      'subregion': 'Southern Europe',
      'tld': [
        '.it'
      ]
    },
    {
      'callingCode': '370',
      'country': 'Lithuania',
      'cca2': 'LT',
      'cca3': 'LTU',
      'currency': 'EUR',
      'name': 'Lithuania',
      'region': 'Europe',
      'subregion': 'Northern Europe',
      'tld': [
        '.lt'
      ]
    },
    {
      'callingCode': '52',
      'country': 'Mexico',
      'cca2': 'MX',
      'cca3': 'MEX',
      'currency': 'MXN',
      'name': 'Mexico',
      'region': 'Americas',
      'subregion': 'Northern America',
      'tld': [
        '.mx'
      ]
    },
    {
      'callingCode': '351',
      'country': 'Portugal',
      'cca2': 'PT',
      'cca3': 'PRT',
      'currency': 'EUR',
      'name': 'Portugal',
      'region': 'Europe',
      'subregion': 'Southern Europe',
      'tld': [
        '.pt'
      ]
    },
    {
      'callingCode': '34',
      'country': 'Spain',
      'cca2': 'ES',
      'cca3': 'ESP',
      'currency': 'EUR',
      'name': 'Spain',
      'region': 'Europe',
      'subregion': 'Southern Europe',
      'tld': [
        '.es'
      ]
    },
    {
      'callingCode': '46',
      'country': 'Sweden',
      'cca2': 'SE',
      'cca3': 'SWE',
      'currency': 'SEK',
      'name': 'Sweden',
      'region': 'Europe',
      'subregion': 'Northern Europe',
      'tld': [
        '.se'
      ]
    },
    {
      'callingCode': '41',
      'country': 'Switzerland',
      'cca2': 'CH',
      'cca3': 'CHE',
      'currency': 'CHF',
      'name': 'Switzerland',
      'region': 'Europe',
      'subregion': 'Western Europe',
      'tld': [
        '.ch'
      ]
    },
    {
      'callingCode': '44',
      'country': 'United Kingdom',
      'cca2': 'GB',
      'cca3': 'GBR',
      'currency': 'GBP',
      'name': 'United Kingdom',
      'region': 'Europe',
      'subregion': 'Northern Europe',
      'tld': [
        '.uk'
      ]
    }
  ];
  listOfEURegions: string[] = [
    'AD', 'AL', 'AT',
    'BA', 'BE', 'BG', 'BY',
    'CY', 'CZ',
    'DK', 'DE',
    'EE', 'ES',
    'FI', 'FO', 'FR',
    'GB', 'GL', 'GR',
    'HR', 'HU',
    'IE', 'IN', 'IS', 'IT',
    'LI', 'LT', 'LU', 'LV',
    'MC', 'MD', 'ME', 'MK', 'MT',
    'NL', 'NO',
    'PL', 'PT',
    'RO', 'RS', 'RU',
    'SE', 'SI', 'SK',
    'TR',
    'UA',
    'VA',
    'XK'
  ];

  private static getRandomIntInclusive(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getMonths(): string[] {
    return this.months;
  }

  setRandomValue(min: number, max: number): void {
    const value = UtilsService.getRandomIntInclusive(min, max);
    this.randomizeEvent$.next(value);
  }

  getRandomValue(): number {
    return this.randomizeEvent$.getValue();
  }

  shuffleReasons<T>(reasons: T[]): void {
    for (let i = reasons.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [reasons[i], reasons[j]] = [reasons[j], reasons[i]];
    }
  }

  rangeRandomCalcValue(max: number): number {
    return Math.floor(Math.random() * max);
  }

  buildWorkingPeriod(breaks: { start: string, end: string }[], workingPeriod: { start: string, end: string }[], openTime: string, closeTime: string): { start: string, end: string }[] {
    breaks.forEach((v, index, array): void => {
      if (index === 0) {
        workingPeriod = [{start: openTime, end: array[index].start}]
      } else {
        workingPeriod = [...workingPeriod, {start: array[index - 1].end, end: array[index].start}];
      }
    });

    return [...workingPeriod, {start: breaks[breaks.length - 1].end, end: closeTime}];  // last item
  }
}
