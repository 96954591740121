import { PromocodeUI, UserProfileComments, Balance } from './index';

export interface User {
  _id: string;
  address: {
    line1: string,
    line2: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    countryCode: string,
    formatted: string,
    geo: {
      latitude: string,
      longitude: string
    }
  };
  aml: {
    status: string;
    history: History[]
  };
  balance: Balance[];
  dateOfBirth: string;
  device: {
    id: string,
    os: string,
    token: string,
    version: string,
    type: string
  };
  email: string;
  firstName: string;
  iban: string;
  ibanSonect: string;
  kyc: UserKyc;
  lastName: string;
  citizenship: string;
  limits: {
    balance: number,
    receive: {
      monthly: number,
      yearly: number
    },
    send: {
      monthly: number,
      yearly: number
    },
    withdraw: {
      daily: number
      free: number,
      monthly: number,
      transaction: number,
      yearly: number,
    }
  };
  limitsMax: {
    balance: number,
    receive: {
      monthly: number,
      yearly: number
    },
    send: {
      monthly: number,
      yearly: number
    },
    withdraw: {
      free: number,
      monthly: number,
      transaction: number,
      yearly: number
      daily: number,
    }
  };
  locale: string;
  middleName: string;
  modificationDate: Date;
  name: string;
  onboardedBy: string;
  partnerMetadata: {
    userId: string;
    brand: string;
    balance: string;
    currency: string;
    [key: string]: any;
  }
  paymentOptions: {
    currencies: {
      active: string,
      all: [string]
    },
    accounts: [any],
    adyen: {
      sources: [any]
    }
    postFinance: {
      alias: string,
      flag: number
    },
    migrosBank: {
      alias: string,
      epayId: string
    }
  };
  password: string;
  payouts: Payout[];
  phone: string;
  promotion?: PromocodeUI;
  ratings: {
    overall: {
      '1': number,
      '2': number,
      '3': number,
      '4': number,
      '5': number
    },
    all: UserRating[] // Array of ratings
  };
  referenceId: string;
  registrationDate: Date;
  social: {
    facebook: string,
    twitter: string,
    whatsapp: string
  };
  status: {
    name: UserStatus,
    modificationDate: string
  };
  tags: string[];
  timezone: {
    id: string,
    name: string
  };
  tin: {
    countryCode: string;
    value: string;
  };
  topups: {
    overall: {
      'CREDIT_CARD': number;
      'DIRECT_DEBIT': number;
      'MANUAL': number;
      'POSTFINANCE': number;
      'SOFORT': number;
    },
    all: UserTopup[]
  };
  verified: boolean;
  verificationDate: Date;
  verificationLevel: number;
  auditReasonMessage?: string;
}

export interface Payout {
  date: Date;
  currency: string;
  amount: number;
}

export interface UserRating {
  rating: number;
  comment: string;
  by: string;
  date: Date;
}

export interface UserTopup {
  amount: number;
  comments: string;
  currency: string;
  date: Date;
  method: string;
  bankId: string;
  referenceId: string;
  referenceId2: string;
  status: string;
  fees: UserFees;
}

export interface History {
  what: any;
  when: Date;
  who: string;
}

export interface UserFees {
  amount: number;
  payer: string;
  status: string;
}

export interface UserKyc {
  attempts: number,
  comments: UserProfileComments[],
  confirmed: boolean,
  dateOfBirth: string,
  dateOfIdentification: Date,
  documentExpiry: string,
  documentType: string,
  file: string,
  gender: string,
  nationality: string,
  status: string,
  sourceOfFunds: string,
  version: number
}

export enum UserStatus {
  Invited = 'INVITED',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blocked = 'BLOCKED',
  Disabled = 'DISABLED',
}

export enum UserTag {
  CUSTOMER = 'customer',
  EMPLOYEE = 'employee',
  TRIAL = 'trial',
}
