import { APP_INITIALIZER, FactoryProvider, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgProgressModule } from '@ngx-progressbar/core';

import { AppRoutingModule } from './app-routing.module';
import { MainButtonModule } from './pages/shared/main-button/main-button.module';
import { LandscapeModeModule } from './pages/protected/landscape-mode/landscape-mode.module';
import { PipesModule } from './pipes/pipes.module';

import { AppComponent } from './app.component';
import {
  AuthenticationService,
  LocationService,
  ShopSearchService,
  ThemeService,
  UserLanguagesService
} from './services';
import { MapService } from './pages/protected/map/services/map.service';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { WrapperColorDirective } from './directives/wrapper-color.directive';
import { THEME_NAME, LOCAL_STORAGE_KEYS, PartnerBrand } from './constants';

function loadConfigFactory(themeService: ThemeService) {
  const url = new URL(window.location.href);
  const brand = url.searchParams.get('brand');
  if (brand === PartnerBrand.LOTTOMATICA) {
    return () => themeService.setTheme(THEME_NAME.WHITE);
  }
  if (brand === PartnerBrand.GOLDBET) {
    return () => themeService.setTheme(THEME_NAME.BLACK);
  }
  const theme = localStorage.getItem(LOCAL_STORAGE_KEYS.theme);
  // The theme settings in the web app are stored in the browser.
  return () => themeService.setTheme(theme ? theme : THEME_NAME.WHITE);
}

function loadMapFactory(mapService: MapService) {
  return () => mapService.loadGoogleMaps();
}

export const loadConfigProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: loadConfigFactory,
  deps: [ThemeService],
  multi: true
};

export const loadMapProvider: FactoryProvider = {
  provide: APP_INITIALIZER,
  useFactory: loadMapFactory,
  deps: [MapService],
  multi: true
}

@NgModule({
  declarations: [
    AppComponent,
    WrapperColorDirective
  ],
  imports: [
    LandscapeModeModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientJsonpModule,
    HttpClientModule,
    NgProgressModule,
    AppRoutingModule,
    MainButtonModule,
    PipesModule.forRoot()
  ],
  providers: [
    AuthenticationService,
    LocationService,
    ShopSearchService,
    UserLanguagesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    loadConfigProvider,
    loadMapProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
