import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  transform(phone: string, countryCode: string): string {
    if (countryCode === 'CH') {
      return '+' + phone.substring(2, phone.length);
    }

    return phone;
  }
}
