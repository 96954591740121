import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { OnboardingSteps, Response, User } from '../interfaces';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  private onBoardingSteps$ = new BehaviorSubject<OnboardingSteps[] | null>(null);
  onBoardingStepsData$: Observable<OnboardingSteps[]> = this.onBoardingSteps$.asObservable();

  private http = inject(HttpClient);

  getSteps(): Observable<Response> {
    return this.http.get<Response>(`${environment.baseurl.api}/websdk/user/onboarding/steps`)
  }

  getOnboardingSteps(): OnboardingSteps[] {
    return this.onBoardingSteps$.getValue();
  }

  setOnboardingSteps(steps: OnboardingSteps[]): void {
    this.onBoardingSteps$.next(steps);
  }

  setPasscode(password: string, referenceId: string): Observable<Response> {
    return this.http.post<Response>(`${environment.baseurl.api}/v7/updatePassword`, { oldPassword: referenceId, newPassword: password });
  }

  changePasscode(password: string): Observable<Response> {
    return this.http.post<Response>(`${environment.baseurl.api}/websdk/password/verify`, { password });
  }

  updatePasscode(oldPassword: string, newPassword: string): Observable<Response> {
    return this.http.post<Response>(`${environment.baseurl.api}/v7/updatePassword`, { oldPassword, newPassword });
  }

  updateUser(user: Partial<User>): Observable<Response> {
    return this.http.patch<Response>(`${environment.baseurl.api}/websdk/user`, { ...user, legalComplianceUpdate: true });
  }

  updateUserCompliance(): Observable<Response> {
    return this.http.patch<Response>(`${environment.baseurl.api}/websdk/user`, { legalComplianceUpdate: true });
  }

  confirmTransactionBySCA(transactionId: string, passcode: string): Observable<Response> {
    return this.http.post<Response>(`${environment.baseurl.api}/transaction/confirmSCA`, { transaction_id: transactionId, passcode });
  }

  recoverPasscodeInit(email: string): Observable<Response> {
    return this.http.post<Response>(`${environment.baseurl.api}/websdk/password/recover`, { email });
  }

  verifyRecoverPasscode(code: string): Observable<Response> {
    return this.http.post<Response>(`${environment.baseurl.api}/websdk/recoveryCode/verify`, { code });
  }

  initKYC(payload: { countryOfIssue?: string }): Observable<Response> {
    return this.http.post<Response>(`${environment.baseurl.api}/v7/kyc/initiateSession`,
      { type: 'user', platform: 'idenfy', data: { ...payload } });
  }

  getOnboardingDetails(user: User) {
    const date: Date | '' = user?.dateOfBirth ? new Date(user.dateOfBirth) : '';
    const dateOfBirth = typeof date === 'object' ? new Date(date.setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset())).toISOString().substring(0, 10) : '';

    return [
      { name: 'authorized.profile_name', value: user?.firstName + ' ' + user?.lastName, feature: 'NAME', controlType: 'text' },
      { name: 'authorized.date_of_birth', value: dateOfBirth, feature: 'DATE_OF_BIRTH', controlType: 'text' },
      { name: 'authorized.email', value: user?.email, feature: 'EMAIL', controlType: 'text' },
      { name: 'authorized.address', value: user?.address?.formatted, feature: 'ADDRESS', controlType: 'text' },
      { name: 'authorized.profile_personal_information_tin', value: user?.tin?.value, feature: 'TIN', controlType: 'text' },
      { name: 'authorized.refund_iban', value: user?.iban, feature: 'IBAN', controlType: 'text' },
      { name: 'authorized.phone', value: user?.phone, feature: 'PHONE', controlType: 'text' },
      { name: 'authorized.citizenship', value: user?.citizenship, feature: 'CITIZENSHIP', controlType: 'text' } // Add this line

    ]
  }
}
