export interface OnboardingSteps {
  id: number;
  step: OnboardingStepName,
  editable: boolean;
  finished: boolean;
  isLegalComplianceUpdated?: boolean;
  children: string[];
}

export type OnboardingStepName = 'PERSONAL_INFORMATION' | 'KYC' | 'PASSWORD_RECOVERY';

export enum OnboardingStepsName {
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  KYC = 'KYC',
  PASSWORD_RECOVERY = 'PASSWORD_RECOVERY'
}

export interface KYCReturnedData {
  url: string;
  showThanksPage: boolean;
}
