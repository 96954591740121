import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { FreshdeskTicketsPayload } from '../pages/protected/map/interfaces/freshdesk-tickets-payload.interface';

@Injectable({
  providedIn: 'root'
})
export class FreshdeskService {

  ticketsToken = btoa('mndxJMHNiLXJWkldxmyy');
  satisfactionToken = btoa('x16FxtDaU28Gitlsd5mF:X');

  constructor(
    private http: HttpClient
  ) {
  }

  // old request for Freshdesk Satisfaction api
  postNewTicket(): Observable<any> {
    return this.http.post<any>('https://sonecthelp.freshdesk.com/api/v2/tickets',
      {
        status: 5,
        priority: 1,
        email: 'twint-feedback@sonect.ch',
        subject: 'TWINT_FEEDBACK',
        description: 'TWINT_FEEDBACK',
        type: 'Feedback'
      }, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Basic ${ this.satisfactionToken }`
        })
      }
    );
  }

  // old request for Freshdesk Satisfaction api
  postFeedback(payload, ticketId: number): Observable<any> {
    return this.http.post<any>(`https://sonecthelp.freshdesk.com/api/v2/tickets/${ ticketId }/satisfaction_ratings`, payload, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Basic ${ this.satisfactionToken }`
        })
      }
    );
  }

  sendTicket(payload: FreshdeskTicketsPayload): Observable<any> {
    return this.http.post('https://sonecthelp.freshdesk.com/api/v2/tickets', payload, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Basic ${ this.ticketsToken }`
      })
    });
  }
}
