import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { NpsStats } from '../interfaces';
import { LOCAL_STORAGE_KEYS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class NpsService {
  npsStats$: BehaviorSubject<NpsStats> = new BehaviorSubject<NpsStats>({
    rating: NaN,
    reason: undefined,
    comment: undefined,
    shop_id: undefined,
    trigger: undefined,
    trigger_count: NaN
  });
  npsRulesSecondEvent$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  npsRulesThirdEvent$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  cancelledHelper$ = new BehaviorSubject(undefined);

  get npsStatsValue(): NpsStats {
    return this.npsStats$.getValue();
  }

  setNpsStats(options: NpsStats): void {
    this.npsStats$.next(options);
  }

  sessionCountTrigger(): void {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.sessionCount)) {
      const count = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.sessionCount)) + 1;
      localStorage.setItem(LOCAL_STORAGE_KEYS.sessionCount, JSON.stringify(count));
    } else {
      const count = 1;
      localStorage.setItem(LOCAL_STORAGE_KEYS.sessionCount, JSON.stringify(count));
    }
  }

  successfulTransactionTrigger(): void {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.successfulTransaction)) {
      const count = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.successfulTransaction)) + 1;
      localStorage.setItem(LOCAL_STORAGE_KEYS.successfulTransaction, JSON.stringify(count));
    } else {
      const count = 1;
      localStorage.setItem(LOCAL_STORAGE_KEYS.successfulTransaction, JSON.stringify(count));
    }
  }

  cancelledTransactionTrigger(): void {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.cancelledTransaction)) {
      const count = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.cancelledTransaction)) + 1;
      localStorage.setItem(LOCAL_STORAGE_KEYS.cancelledTransaction, JSON.stringify(count));
    } else {
      const count = 1;
      localStorage.setItem(LOCAL_STORAGE_KEYS.cancelledTransaction, JSON.stringify(count));
    }
  }
}
