import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js';

import { BehaviorSubject } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  amplitudeEvent$ = new BehaviorSubject(null);
  amplitude$ = this.amplitudeEvent$.asObservable();

  constructor(private authenticationService: AuthenticationService) {}

  get isToken(): boolean {
    return !!this.authenticationService.getAccessToken();
  }

  initAmplitude(userId: string): void {
    if (!Object.prototype.hasOwnProperty.call(environment, 'turnOffAmplitude') && this.isToken) {
      amplitude.init(environment.amplitudeApiKey, userId, {
        includeReferrer: true,
        includeUtm: true,
        disableCookies: true
      });
    }
  }

  sendAmplitudeEvent(event: string, eventProperties?): void {
    if (!Object.prototype.hasOwnProperty.call(environment, 'turnOffAmplitude') && this.isToken) {
      if (eventProperties) {
        amplitude.getInstance().logEvent(event, eventProperties);
        return;
      }
      amplitude.getInstance().logEvent(event);
    }
  }

  setIdentify(scheme: string): void {
    const identify = new amplitude.Identify().set('issuerId', scheme)
    amplitude.getInstance().identify(identify);
  }

  setAmplitude(userId): void {
    this.amplitudeEvent$.next(userId);
  }

  getAmplitude(): string {
    return this.amplitudeEvent$.getValue();
  }
}
