import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  Fees,
  PaymentMethodOptionAllowedType,
  PaymentMethodUI,
  Response,
  TransactionPayload
} from '../interfaces';
import { SESSION_STORAGE_KEYS, SONECT_LOGIN_CREDENTIALS } from '../constants';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  transactionId$ = new BehaviorSubject<string>('');
  private http: HttpClient = inject(HttpClient);

  get clientId(): string {
    return SONECT_LOGIN_CREDENTIALS[environment.name].clientId;
  }

  get clientSecret(): string {
    return SONECT_LOGIN_CREDENTIALS[environment.name].clientSecret;
  }

  cancelTransaction(id: string): Observable<Response> {
    return this.http.post<Response>(`${environment.baseurl.api}/v7/transaction/cancel`, { transaction_id: id });
  }

  createTransactionWithdraw(payload: TransactionPayload): Observable<Response> {
    return this.http.post<Response>(`${environment.baseurl.api}/v7/transaction/withdraw`, payload);
  }

  getTransactions(status?: string): Observable<Response> {
    return status ?
      this.http.get<Response>(`${environment.baseurl.api}/transaction/all`, { params: { status } }) :
      this.http.get<Response>(`${environment.baseurl.api}/transaction/all`);
  }

  barcodeConfirmation(payload: { transactionId: string, confirm: boolean }): Observable<Response> {
    const basicToken = 'Basic ' + window.btoa(`${this.clientId}:${this.clientSecret}`);

    return this.http.post<Response>(`${environment.baseurl.api}/v7/partner/payment/complete`, payload, {
      headers: new HttpHeaders({ Authorization: basicToken })
    });
  }

  getWithdrawalBarcode(id: string, code: string): Observable<Blob> {
    return this.http.get(`${environment.baseurl.api}/barcode?height=50`, {
      params: { text: id, label: code },
      responseType: 'blob'
    });
  }

  getWithdrawalQrcode(id, code): Observable<Blob> {
    return this.http.get(`${environment.baseurl.api}/qr`, {
      params: {
        text: id,
        label: code
      },
      responseType: 'blob'
    });
  }

  getTransactionDetails(id: string): Observable<Response> {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ this.getAccessToken() }`
      });
    return this.http.get<Response>(`${environment.baseurl.api}/transaction`, {
      headers,
      params: {id}
    });
  }

  getSonectWallet(withdraw: Fees, topup: Fees, transfer: Fees): PaymentMethodUI {
    return {
      id: 'Sonect Wallet',
      active: false,
      imagePath: '',
      name: 'Sonect Wallet',
      options: {
        allowed: [PaymentMethodOptionAllowedType.WITHDRAWAL],
        fees: { withdraw, topup, transfer }
      },
      paymentMethod: 'BALANCE',
      paymentMethodId: '',
    };
  }

  cancelTransactionBeforeRecovery(): Observable<boolean | Response> {
    return this.getTransactions('pending')
      .pipe(
        switchMap((response: Response) => {
          if (response.payload.length) {
            return of(response);
          } else {
            return this.getTransactions('pending_sca');
          }
        }),
        map((response: Response) => response.payload[0]),
        switchMap((transaction) => {
          if (transaction) {
            return this.cancelTransaction(transaction.id)
          }

          return of(true);
        })
      )
  }

  private getAccessToken(): any {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.accessToken);
  }
}
