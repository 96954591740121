import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(private httpClient: HttpClient) {
  }

  // loadGoogleMaps(): Observable<Object> {
  loadGoogleMaps(): Observable<unknown> {
    return this.httpClient.jsonp(
      `https://maps.googleapis.com/maps/api/js?key=${ environment.google.apiKey }&map_ids=${ environment.google.mapId }&libraries=places`,
      'callback'
    ).pipe(
      catchError((err) => {
        console.log(err)
        return of([])
      })
    );
  }

  setSearchHeight(el, height: number): void {
    const search = el.closest('.search') as HTMLInputElement;
    search.style.height = height + 'px';
  }


  // help to detect country, city ...
  // Should be in google cloud panel
  // https://developers.google.com/maps/documentation/geocoding/usage-and-billing
  // https://console.cloud.google.com/apis/library/geocoding-backend.googleapis.com?project=test-sonect-app-user
  geocodeAPI({lat, lng}: google.maps.LatLngLiteral) {
    return this.httpClient.get(`https://maps.googleapis.com/maps/api/geocode/json?key=${ environment.google.apiKey }&latlng=${ lat },${ lng }&sensor=false`);
  }
}
