import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeCase'
})
export class CapitalizeCasePipe implements PipeTransform {
  transform(value: string): string {
    value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    return value;
  }
}
