import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { firstValueFrom } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { Response, UserTopup } from '../interfaces';
import { environment } from '../../environments/environment';

interface PaymentMethod {
  amount: number;
  currency: string;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private http = inject(HttpClient);
  private authenticate = inject(AuthenticationService);

  getPaymentMethods({amount, currency}: PaymentMethod): Promise<Response> {
    const headers = this.authenticate.getHeaders();
    return firstValueFrom(this.http.post<Response>(`${environment.baseurl.api}/card/payment/method`, {
      amount,
      currency
    }, headers));
  }

  makePayment(data): Promise<Response> {
    return firstValueFrom(this.http.post<Response>(`${environment.baseurl.api}/card/payment`, data));
  }

  makeDetailsCall(data): Promise<Response> {
    return firstValueFrom(this.http.post<Response>(`${environment.baseurl.api}/card/payment/details`, data));
  }

  calculateWalletSum(all: UserTopup[]): number {
    // be aware of the topup transaction status
    let sum = 0;
    all.forEach((item: UserTopup) => sum += item.amount);
    return sum;
  }
}
