export const THEME_PALETTE = {
  default: {
    sonectBackgroundColor1: '#000000',
    sonectPrimaryColor1: '#FF0069',
    sonectPrimaryColor3: '#FFA300',
    sonectTitleColor: '#FFFFFF',
    sonectTitleColorActive: '#FFFFFF',
    sonectBackgroundColor2: '#202020',
    sonectBackgroundColor3: '#393939',
    sonectBackgroundControl: '#393939',
    sonectDescriptionColor: '#9B9B9B',
    sonectMessage: '#9B9B9B',
    sonectIconColor1: '#FFFFFF',
    sonectGreyDark: '#202020',
    sonectGreyLight: '#393939',
    sonectCardName: '#9B9B9B',
    sonectUserCard: '#FDFDFD',
    sonectStatusTextColor3: '#9B9B9B',
    sonectBorderColor1: 'transparent',
    sonectItem: '#151515',
    sonectSpotlightTitle: '#20202080',
    sonectWithdrawCard: 'linear-gradient(45deg, #202020 0%, #212121 2.08%, #575757 100%)'
  },
  white: {
    sonectBackgroundColor1: '#F5F5F5',
    // sonectPrimaryColor1: "#009455",
    sonectPrimaryColor1: '#FF0069',
    sonectPrimaryColor3: '#FFA300',
    sonectTitleColor: '#000000',
    sonectTitleColorActive: '#FFFFFF',
    sonectBackgroundColor2: '#FFFFFF',
    sonectBackgroundColor3: 'transparent',
    sonectBackgroundControl: '#F2F2F2',
    sonectDescriptionColor: '#C8C8C8',
    sonectMessage: '#111111b3',
    sonectIconColor1: '#000000',
    sonectGreyDark: '#E5E5E5',
    sonectGreyLight: '#F5F5F5',
    sonectCardName: '#FFA300',
    sonectUserCard: '#FDFDFD',
    sonectStatusTextColor3: '#000000',
    sonectBorderColor1: '#9B9B9B',
    sonectItem: '#F2F2F2',
    sonectSpotlightTitle: '#f1f1f180',
    sonectWithdrawCard: 'linear-gradient(45deg, #F5F5F5 0%, #DAD9D9 2.08%, #FFFFFF 100%)'
  }
};

export enum THEME_NAME {
  WHITE = 'white',
  BLACK = 'default'
}
