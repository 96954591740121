import { NgModule } from '@angular/core';

import { DistancePipe } from './distance.pipe';
import { CapitalizeCasePipe } from './capitalizeCase.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { SortByDayPipe } from './sort-by-day.pipe';
import { CardWorkingHoursPipe } from './cardWorkingHours.pipe';
import { FirstLetterPipe } from './first-letter.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';

@NgModule({
  declarations: [DistancePipe, CapitalizeCasePipe, FormatDatePipe, SortByDayPipe, CardWorkingHoursPipe, FirstLetterPipe, PhoneNumberPipe],
  imports: [],
  exports: [DistancePipe, CapitalizeCasePipe, FormatDatePipe, SortByDayPipe, CardWorkingHoursPipe, FirstLetterPipe, PhoneNumberPipe]
})
export class PipesModule {
  static forRoot() {
    return {
      ngModule: PipesModule,
      providers: [],
    };
  }
}
