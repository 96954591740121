import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard } from './guards/authentication.guard';
import { loginGuard } from './guards/login.guard';
import { promocodeGuard } from './guards/promocode.guard';
import { onboardingGuard } from './guards/onboarding.guard';
import { kycInitGuard } from './guards/kyc-init.guard';
import { changePasscodeGuard } from './guards/change-passcode.guard';

import { userResolver } from './pages/protected/dashboard/user.resolver';
import { onBoardingResolver } from './resolvers/onBoarding.resolver';

const routes: Routes = [
  {
    path: 'start',
    loadChildren: () => import('./pages/public/start/start.module').then(m => m.StartModule)
  },
  {
    path: 'expired',
    loadChildren: () => import('./pages/public/session-expired/session-expired.module').then(m => m.SessionExpiredModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/protected/login/login.module').then(m => m.LoginModule),
    canActivate: [loginGuard],
  },
  {
    path: '',
    canActivateChild: [authGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/protected/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { animation: 'dashboard' },
        resolve: { onboarding: onBoardingResolver }
      },
      {
        path: 'map',
        loadChildren: () => import('./pages/protected/map/map.module').then(m => m.MapModule),
        data: { animation: 'map' }
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/protected/profile/profile.module').then(m => m.ProfileModule),
        data: { animation: 'profile' },
        resolve: { onboarding: onBoardingResolver }
      },
      {
        path: 'profile/contact',
        loadChildren: () => import('./pages/protected/contact/contact.module').then(m => m.ContactModule),
        data: { animation: 'contact' }
      },
      {
        path: 'profile/about',
        loadChildren: () => import('./pages/protected/about-us/about-us.module').then(m => m.AboutUsModule),
        data: { animation: 'about' }
      },
      {
        path: 'profile/settings',
        loadChildren: () => import('./pages/protected/settings/settings.module').then(m => m.SettingsModule),
        data: { animation: 'settings' }
      },
      {
        path: 'change-passcode',
        loadChildren: () => import('./pages/protected/change-passcode/change-passcode.module').then(m => m.ChangePasscodeModule),
        canActivate: [changePasscodeGuard],
        data: { animation: 'change-passcode' }
      },
      {
        path: 'recovery-passcode',
        loadChildren: () => import('./pages/protected/recovery-passcode/recovery-passcode.module').then(m => m.RecoveryPasscodeModule),
        data: { animation: 'recovery-passcode' }
      },
      {
        path: 'monitor',
        loadChildren: () => import('./pages/protected/transaction-list/transaction-list.module').then(m => m.TransactionListModule),
        data: { animation: 'transaction-list' }
      },
      {
        path: 'transaction/:id',
        loadChildren: () => import('./pages/protected/transaction-details/transaction-details.module').then(m => m.TransactionDetailsModule),
        data: { animation: 'transaction-details' }
      },
      {
        path: 'tutorial',
        loadChildren: () => import('./pages/protected/tutorial/tutorial.module').then(m => m.TutorialModule),
        data: { animation: 'tutorial' }
      },
      {
        path: 'withdraw',
        loadChildren: () => import('./pages/protected/withdraw/withdraw.module').then(m => m.WithdrawModule),
        data: { animation: 'withdraw' },
        resolve: { onboarding: onBoardingResolver }
      },
      {
        path: 'promocode',
        loadChildren: () => import('./pages/protected/promocode/promocode.module').then(m => m.PromocodeModule),
        canActivate: [promocodeGuard],
        data: { animation: 'promocode' }
      },
      {
        path: 'top-up',
        loadChildren: () => import('./pages/protected/top-up/top-up.module').then(m => m.TopUpModule),
        data: { animation: 'top-up' },
        resolve: { onboarding: onBoardingResolver }
      },
      {
        path: 'onboarding',
        loadChildren: () => import('./pages/protected/onboarding/onboarding.module').then(m => m.OnboardingModule),
        data: { animation: 'onboarding' },
        // canActivate: [onboardingGuard],
        resolve: { user: userResolver, onboarding: onBoardingResolver }
      },
      {
        path: 'onboarding/passcode',
        loadChildren: () => import('./pages/protected/activation-code/activation-code.module').then(m => m.ActivationCodeModule),
        data: { animation: 'code' },
        // canActivate: [onboardingGuard],
        resolve: { user: userResolver }
      },
      {
        path: 'kyc/init',
        loadChildren: () => import('./pages/protected/idenfy/idenfy.module').then(m => m.IdenfyModule),
        data: { animation: 'kyc' },
        // canActivate: [kycInitGuard],
      },
      {
        path: 'kyc/status/:statusName',
        loadChildren: () => import('./pages/protected/kyc-status/kyc-status.module').then(m => m.KycStatusModule),
        data: { animation: 'kyc' },
      },
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    ]
  },
  { path: '', redirectTo: '/start', pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
