import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OnboardingService } from '../services';
import { OnboardingStepsName, Response } from '../interfaces';

export const changePasscodeGuard = (): Observable<UrlTree | boolean> => {
  const onboardingService = inject(OnboardingService);
  const router = inject(Router);

  return onboardingService.getSteps()
    .pipe(
      map((response: Response) => response.payload.find(({ step }) => step === OnboardingStepsName.PASSWORD_RECOVERY).finished),
      map((isFinished: boolean) => {
        if (!isFinished) {
          return router.createUrlTree(['/recovery-passcode']);
        }

        return true;
      })
    );
}
