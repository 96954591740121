export enum UserConfiguration {
  APPLY_PARTNER_FEES = 'APPLY_PARTNER_FEES',
  APPLY_PARTNER_TAGS = 'APPLY_PARTNER_TAGS',
  CAPTURE_TC_PP = 'CAPTURE_TC_PP',
  CAPTURE_TC_PP_CUSTOM = 'CAPTURE_TC_PP_CUSTOM',
  CAPTURE_USER_EMAIL = 'CAPTURE_USER_EMAIL',
  CAPTURE_USER_IBAN = 'CAPTURE_USER_IBAN',
  CAPTURE_USER_INFO_EMAIL = 'CAPTURE_USER_INFO_EMAIL',
  CONTACT_US = 'CONTACT_US',
  CONTACT_US_PARTNER = 'CONTACT_US_PARTNER',
  DASHBOARD_BACK_BUTTON = 'DASHBOARD_BACK_BUTTON',
  DASHBOARD_EXPLORE = 'DASHBOARD_EXPLORE',
  DASHBOARD_MONITOR = 'DASHBOARD_MONITOR',
  DASHBOARD_MONITOR_LIMITS = 'DASHBOARD_MONITOR_LIMITS',
  DASHBOARD_MONITOR_TRANSACTION = 'DASHBOARD_MONITOR_TRANSACTION',
  DASHBOARD_PROFILE = 'DASHBOARD_PROFILE',
  DASHBOARD_SPOTLIGHT = 'DASHBOARD_SPOTLIGHT',
  DASHBOARD_TOPUP = 'DASHBOARD_TOPUP',
  DASHBOARD_WITHDRAW = 'DASHBOARD_WITHDRAW',
  INFO_MESSAGES_WITHDRAW_SCREEN = 'INFO_MESSAGES_WITHDRAW_SCREEN',
  LIMIT_AMOUNT_RECEIVE_MONTHLY = 'LIMIT_AMOUNT_RECEIVE_MONTHLY',
  LIMIT_AMOUNT_RECEIVE_YEARLY = 'LIMIT_AMOUNT_RECEIVE_YEARLY',
  LIMIT_AMOUNT_SEND_MONTHLY = 'LIMIT_AMOUNT_SEND_MONTHLY',
  LIMIT_AMOUNT_SEND_YEARLY = 'LIMIT_AMOUNT_SEND_YEARLY',
  LIMIT_AMOUNT_WITHDRAW_DAILY = 'LIMIT_AMOUNT_WITHDRAW_DAILY',
  LIMIT_AMOUNT_WITHDRAW_MONTHLY = 'LIMIT_AMOUNT_WITHDRAW_MONTHLY',
  LIMIT_AMOUNT_WITHDRAW_YEARLY = 'LIMIT_AMOUNT_WITHDRAW_YEARLY',
  TRANSACTION_CANCEL_BUTTON = 'TRANSACTION_CANCEL_BUTTON',
}
