export interface Fees {
  fixed: number;
  payer: FeesPayerType;
  trial: boolean;
  variable: FeesVariable;
}

export interface FeesVariable {
  value: number;
  type: FeesVariableType;
  of: FeesVariablePercentOfType;
}

export enum FeesPayerType {
  CUSTOMER = 'customer',
  PARTNER = 'partner'
}

export enum FeesVariableType {
  NONE = '',
  FIXED = 'fixed',
  PERCENT = 'percent'
}

export enum FeesVariablePercentOfType {
  NONE = '',
  AMOUNT = 'amount',
  FIXED = 'fixed'
}

// uses in fees-calculating.service
export interface Fee {
  fixed: number;
  min: number;
  max: number;
  variable: {
    value: number,
    type: string,
    of: string,
  };
  payer: string;
  trial: boolean;
}
