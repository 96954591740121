import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';

import { UserLanguagesService } from './user-languages.service';
import { ApplicationConfiguration, Response, User, Login, LoginExtended, UserCreation } from '../interfaces';
import { SESSION_STORAGE_KEYS, LOCAL_STORAGE_KEYS } from '../constants';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  configuration$ = new BehaviorSubject<ApplicationConfiguration | null>(null);
  private user$ = new BehaviorSubject<User | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private languagesService: UserLanguagesService
  ) {}

  clear(): void {
    sessionStorage.clear(); // keep this line to be sure that's all items is removed
  }

  getAccessToken(): string {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.accessToken);
  }

  getAppConfiguration(): ApplicationConfiguration {
    return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.configuration));
  }

  generateConfiguration(clientId: string, codeChallenge: string, os: string, scheme: string, signature: string, token: string): Observable<HttpResponse<Response>> {
    const locale = this.languagesService.getUserLanguageWithLocal();
    // Check if referrer value is stored in the localStorage
    const referrer = localStorage.getItem(LOCAL_STORAGE_KEYS.referrer) ?? '';

    return this.http.post<Response>(
      `${environment.baseurl.api}/v7/app`,
      { client_id: clientId, code_challenge: codeChallenge, os, referrer, scheme, signature, token },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept-Language': locale
        }),
        observe: 'response',
        responseType: 'json'
      });
  }

  getHeaders(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'accept-language': this.languagesService.getUserLanguageWithLocal(),
        Authorization: `Bearer ${this.getAccessToken()}`
      })
    };
  }

  getToken(clientId: string, code: string, codeVerifier: string): Observable<HttpResponse<Response>> {
    return this.http.post<Response>(`${environment.baseurl.api}/v7/oauth2/token`,
      { client_id: clientId, code, code_verifier: codeVerifier },
      { observe: 'response', responseType: 'json' });
  }

  getUserInfoPayment(): Observable<Response> {
    return this.http.get<Response>(`${environment.baseurl.api}/v7/user`);
  }

  setConfiguration(config: ApplicationConfiguration): void {
    this.configuration$.next(config);
  }

  setUser(user: User): void {
    this.user$.next(user);
  }

  getUser(): User {
    return this.user$.getValue();
  }

  exist({ clientId, clientSecret, referenceId, bundleId }: LoginExtended): Observable<Response> {
    const basicToken = 'Basic ' + window.btoa(`${clientId}:${clientSecret}`);

    return this.http.post<Response>(`${environment.baseurl.api}/v7/exists`, { userType: 'user', id: referenceId },
      { headers: new HttpHeaders({ Authorization: basicToken, 'X-Sonect-Bundle-Id': bundleId }) });
  }

  login({ clientId, clientSecret, referenceId, brand, userBalance, urlBack }: Login | LoginExtended): Observable<{ checkInUrl: string }> {
    const basicToken = 'Basic ' + window.btoa(`${clientId}:${clientSecret}`);

    return this.http.post<{ checkInUrl: string }>(`${environment.baseurl.api}/v7/partnerCheckin`, { referenceId, additionalData: { brand, userId: '6772727', balance: userBalance, currency: 'EUR', urlBack } },
      { headers: new HttpHeaders({ Authorization: basicToken }) });
  }

  createUser(
    { clientId, clientSecret, referenceId, bundleId }: LoginExtended,
    user: UserCreation
  ) {
    const basicToken = 'Basic ' + window.btoa(`${clientId}:${clientSecret}`);

    return this.http.post(`${environment.baseurl.api}/user`, { ...user, referenceId },
      { headers: new HttpHeaders({ Authorization: basicToken, 'X-Sonect-Bundle-Id': bundleId }) });
  }
}
