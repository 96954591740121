import { Fees } from './fee';

export interface ApplicationConfiguration {
  availablePaymentMethods: {
    banks: PaymentMethod[];
    others: PaymentMethod[];
  };
  backButtonLink?: string;
  contact: ContactFields;
  features: string[];
  denominations: number[];
  fees: {
    fixed: number;
    payer: FeesBy;
    trial: boolean;
    variable: {
      value: number;
      type: string;
      of: string;
    };
  };
  expiry: {
    transaction: {
      withdraw: number
    }
  },
  legalCompliance?: {
    language: string;
    privacyPolicy: string;
    termsAndConditions: string;
  };
  method: string;
  pricing: {
    fees: Fees[],
    tags: [],
    trial: false
  };
  step: number;
  showTermsAndConditions: boolean;
  tags: string[];
  spotlight: SpotlightCondition;
}

export type SpotlightCondition = {
  [key: string]: string[];
}

export type ContactFields = {
  [key: string]: string;
}

export interface PaymentMethod {
  imagePath: string;
  name: string;
  options: PaymentMethodOption;
  paymentMethod: string;
  paymentMethodId: string;
}

export interface PaymentMethodUI extends PaymentMethod {
  id: string;
  active: boolean;
}

export enum PaymentMethodType {
  BALANCE = 'BALANCE',
  CREDIT_CARD = 'CREDIT_CARD',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  MANUAL = 'MANUAL',
  MIGROS_BANK = 'MIGROS_BANK',
  POSTFINANCE = 'POSTFINANCE',
  SOFORT = 'SOFORT',
  TWINT = 'TWINT'
}

export interface PaymentMethodOption {
  allowed: PaymentMethodOptionAllowed[];
  fees: {
    topup: Fees,
    transfer: Fees,
    withdraw: Fees
  };
  // bankCode: string;
  // country: string;
  // countryCode: string;
  // limits: { [ key: string ]: PaymentMethodOptionLimits };
}

export enum PaymentMethodCategory {
  BANK = 'BANK',
  OTHER = 'OTHER',
}

export type PaymentMethodOptionAllowed = PaymentMethodOptionAllowedType | string[];

export enum PaymentMethodOptionAllowedType {
  WITHDRAWAL = 'WITHDRAWAL',
  TOPUP = 'TOPUP',
  TRANSFER = 'TRANSFER',
}

export interface PaymentMethodOptionLimits {
  max: number;
}

export type feesBy = 'customer' | 'partner';

export enum FeesBy {
  PARTNER = 'partner',
  CUSTOMER = 'customer'
}
