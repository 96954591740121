import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetter'
})
export class FirstLetterPipe implements PipeTransform {
  transform(firstName: string, lastName?: string): string {
    if (lastName) {
      return (firstName.substring(0, 1) + lastName.substring(0, 1)).toUpperCase();
    }

    return firstName.substring(0, 1).toUpperCase();
  }
}
